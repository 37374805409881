import * as React from 'react'
import { OffersState, OffersProps, Offer, Container, NotificationsVariants } from './'
import * as classNames from 'classnames'
import OfferContainer from '../../components/OffersContainer/OfferContainer'
import Layout from '../../components/Layout/Layout'
import './Offer.scss'
import { request, setToken, setUser, getToken, logout } from '../../utils/network'
import { ApiOperation } from '../../utils/api'
import { navigate } from '@reach/router'
import { format } from 'date-fns'
import { Modal, Alert } from 'react-bootstrap'
import ModalHeader from '../../components/ModalWindow/ModalHeader/ModalHeader'
import { Loader } from '../../components/Loader/Loader'
import { ButtonWithLoader } from '../../components/ButtonWithLoader/ButtonWithLoader'
import * as queryString from 'query-string'


class Offers extends React.Component<OffersProps, OffersState> {
  constructor(props: OffersProps) {
    super(props)

    this.state = {
      offer: {
        containers: [],
        usergroup: {
          contact: '',
          id: '',
          createdAt: '',
          currency: '',
          description: '',
          name: '',
          offer: '',
          weight: '',
          updatedAt: '',
        }
      },
      isSubmiting: false,
      isLoading: true,
      disabledBtn: true,
      avaiableOffers: true,
      showError: false,
      showModal: false,
      showNotification: false,
      notificationVariant: 'success',
      notificationMsg: ''
    }
  }

  handleSubmit = () => {
    const { offer } = this.state
    const containers = offer.containers.map((cont: Container) => {
      const priceSelected = cont.meat.some(
        item => item.prices && (item.prices.bid || item.prices.price)
      )
      const priceNotSelected = cont.meat.some(
        item => item.prices && !item.prices.bid && !item.prices.price
      )

      const hasError = priceSelected && priceNotSelected

      cont['hasError'] = hasError

      return cont
    })

    const hasError = containers.some((cont: Container) => cont.hasError)

    if (!hasError) {
      this.toggleModal()
    }

    this.setState({
      offer: { ...offer, containers },
      showError: true,
    })
  }

  sendFormData = () => {
    const { offer } = this.state
    const selectedOffer = offer.containers.filter((cont: Container) => cont.touched)

    this.setState({ isSubmiting: true })

    request({
      operation: ApiOperation.PostOffer,
      data: selectedOffer,
    })
      .then(resp => {
        this.setState({ isSubmiting: false })
        this.toggleModal()
        navigate('/')
      })
      .catch((err) => {
        this.setState({ isSubmiting: false })
        this.showNotification(true, 'danger', 'Oops. Something goes wrong.')
      })
  }

  showNotification = (value: boolean, variant: NotificationsVariants = undefined, msg: string = '') => {
    this.setState({
      showNotification: value,
      notificationVariant: variant,
      notificationMsg: msg
    })
    setTimeout(() => {
      this.setState({ showNotification: false })
    }, 3000)
  }

  selectMeat = ({ containerId, meatId, bid, price }: any): void => {
    const { offer } = this.state
    const containers = offer.containers.map(cont => {
      if (cont.id === containerId) {
        const meat = cont.meat.map(item => {
          if (item.id === meatId) {
            item.prices = {
              bid,
              price,
            }
          }

          return item
        })

        cont.meat = meat
        cont.touched = true
      }

      return cont
    })

    this.setState({
      offer: { ...offer, containers },
      disabledBtn: false
    })
  }

  componentDidMount() {
    const { location } = this.props
    const { secret = '' } = queryString.parse(location.search)
    const getOffers = () => {
      return request({ operation: ApiOperation.GetOffers })
        .then((offer: Offer) => {
          const containers = offer.containers.map(item => {
            item.hasError = false
            item.touched = false
            const meat = item.meat.map(item => ({
              ...item,
              prices: { price: null, bid: null },
            }))

            return { ...item, meat }
          })

          this.setState({
            offer: { ...offer, containers },
            avaiableOffers: !!containers.length,
            isLoading: false
          })
        })
        .catch((err: any) => {
          this.setState({
            avaiableOffers: false,
            isLoading: false
          })
        })
    }

    if (secret) {
      request({
        operation: ApiOperation.GetTokenBySecret,
        data: { secret }
      }).then(({ jwt, user }) => {
        setToken(jwt)
        setUser(user.username || '')
        getOffers()
      })
        .catch(() => {
          if(getToken()) {
            getOffers()
          } else {
            logout()
          }
        })
    } else {
      getOffers()
    }
  }

  toggleModal = (card = {}) => {
    this.setState(({ showModal }) => ({
      showModal: !showModal,
    }))
  }

  render() {
    const {
      offer,
      showError,
      isLoading,
      isSubmiting,
      disabledBtn,
      avaiableOffers,
      showModal,

      showNotification,
      notificationVariant,
      notificationMsg
    } = this.state

    const { usergroup: { currency = '', weight: weightUnits = '' } } = offer

    return (
      <Layout seo={{ title: 'Home' }}>
        {showNotification && (
          <div className="toasts-container">
            <Alert variant={notificationVariant} onClose={() => { this.showNotification(false) }} dismissible>
              <p>{notificationMsg}</p>
            </Alert>
          </div>
        )}
        <Loader isLoading={isLoading}>
          <div className="container offer-page">
            <div className="row">
              <div className="col">
                <div className="offer-page-header">
                  <div className="offer-header-info">
                    <div className="row">
                      <div className="col-6">
                        <h1>Offers</h1>
                        {offer && offer.terms && (
                          <p className="offers-terms">{offer.terms}</p>
                        )}
                        {offer && offer.description && (
                          <p className="offers-description">{offer.description}</p>
                        )}
                      </div>
                      <div className="col-6 right-column-wrapper">
                        <button
                          onClick={this.handleSubmit}
                          className={classNames({
                            'black-bg': !disabledBtn,
                          })}
                          disabled={disabledBtn}
                        >
                          Submit order
                        </button>
                        {offer && offer.lastPublished && (
                          <p>Last updated {format(offer.lastPublished, 'MM/DD/YYYY')}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {avaiableOffers && offer && (
                  <table className="offer-page-table">
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Description</th>
                        <th>Code</th>
                        <th>Brand</th>
                        <th>Quantity</th>
                        <th>Shipment</th>
                        <th>{`CIF ${currency}/${weightUnits}`}</th>
                        <th />
                      </tr>
                    </thead>

                    <tbody>
                      {
                        offer.containers.map((container: Container) => (
                          <OfferContainer
                            key={container.size}
                            data={{
                              ...container,
                            }}
                            selectMeat={this.selectMeat}
                            showError={showError}
                            weightUnits={weightUnits}
                          />
                        ))}
                    </tbody>
                  </table>
                )}
                {!avaiableOffers && (
                  <div className="info-hint">No suggestions found</div>
                )}
              </div>
            </div>
            <Modal
              centered
              show={showModal}
              className="app-modal"
              onHide={() => this.toggleModal()}
            >
              <ModalHeader
                onClose={() => this.toggleModal()}
                title="Thank you for lodging your invitation to purchase."
                className="offers-modal-header"
              />
              <div className="offers-modal">
                <Modal.Body>
                  <p>Please note that acceptance of your offer to purchase at this price is subject to availability at the exact time it is received. A confirmation may take up to 24 hours during which time your acceptance remains valid with Allegro.</p>
                  <p>Confirmation of the sale and signed contractual terms of sale will be confirmed to you by return e-mail. If you have any questions about this please contact us.</p>
                  <p>If you have placed a bid, the bid you have placed will remain valid for 24 hours after it is placed. Allegro reserves the right to accept the bid by return e-mail at any time within that 24 hour period.</p>
                </Modal.Body>
                <Modal.Footer className="btn-container">
                  <button type="button" className="btn btn-white" onClick={this.toggleModal}>Cancel</button>
                  <ButtonWithLoader
                    isLoading={isSubmiting}
                    text="Сonfirm"
                    type="button"
                    className="btn btn-black"
                    onClick={this.sendFormData}
                  />
                </Modal.Footer>
              </div>
            </Modal>
          </div>
        </Loader>


      </Layout>
    )
  }
}

export default Offers
