import * as React from 'react'

export type ButtonWithLoader = {
  isLoading: boolean
  text: string
}

export const ButtonWithLoader = (props: ButtonWithLoader & any) => {
  const {
    isLoading = false,
    text,
    ...buttonProps
  } = props

  return (
    <button {...buttonProps}>
      {
        isLoading
          ? (
            <span className="spinner-border text-light" role="status" style={{ width: '1em', height: '1em', borderWidth: '2px' }}>
              <span className="sr-only">Loading...</span>
            </span>
          )
          : text
      }
    </button>
  )
}