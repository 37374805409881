import * as React from 'react'
import * as classNames from 'classnames'
import './OfferMeatRow.scss'
import Tooltip from '../../components/Tooltip/Tooltip'

import { OfferMeatState, OfferMeatProps } from './'

class OfferMeatRow extends React.Component<OfferMeatProps, OfferMeatState> {
  constructor(props: any) {
    super(props)

    this.state = {
      visibleInput: false,
      inputValue: '',
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      inputValue: event.target.value,
    })
  }

  buyHandler = () => {
    const {
      containerId,
      data: { id, price },
    } = this.props

    this.props.selectMeat({
      containerId,
      meatId: id,
      bid: null,
      price,
    })

    this.setState({
      visibleInput: false,
      inputValue: '',
    })
  }

  handleBlur = () => {
    const {
      containerId,
      data: { id, price },
    } = this.props
    const { inputValue } = this.state

    if (price != inputValue && inputValue) {
      this.props.selectMeat({
        containerId,
        meatId: id,
        bid: inputValue,
        price: null,
      })
    }

    this.setState(prevState => {
      return {
        ...prevState,
        visibleInput: false,
      }
    })
  }

  bidHandler = () => {
    this.setState({
      visibleInput: true,
    })
  }

  render() {
    const {
      data: { prices, price, name = '', code, quantity, image, brand, sale },
      soldOut,
      className,
      showError,
      hasError,
      weightUnits,
    } = this.props
    const isExpense = !prices.bid && !prices.price
    const { visibleInput } = this.state

    return (
      <tr className={classNames('data-row', ...className)}>
        <td>
          {!!image && (
            <img
              className="meat-item-preview"
              src={`${process.env.GATSBY_API_URL}${image}`}
              alt={name}
            />
          )}
        </td>
        <td className="meat-item-name">{name}</td>
        <td>{code}</td>
        <td className="meat-item-brand">{brand || this.props.brand}</td>
        <td>
          {quantity}
          {weightUnits}
        </td>
        <td>{soldOut}</td>
        <td>
          <div className="bid-container">
            {visibleInput && (
              <Tooltip
                title="Please enter your buy price here"
                id="bid"
                trigger="focus"
                onExited={this.handleBlur}
              >
                <input
                  min="0"
                  type="number"
                  placeholder={prices.bid || price}
                  onChange={this.handleChange}
                  value={this.state.inputValue}
                  autoFocus={true}
                />
              </Tooltip>
            )}

            <span className={classNames({ bidPrice: !!prices.bid, sale })}>
              {!visibleInput ? price : ''}
            </span>

            {!visibleInput && <span>{prices.bid}</span>}
          </div>
        </td>
        <td className="row-action">
          {!soldOut && (
            <div className="row-action-buttons">
              <button
                className={classNames({
                  'row-action-btn': true,
                  'black-bg': !!prices.bid,
                })}
                type="button"
                onClick={this.bidHandler}
              >
                Bid
              </button>
              <button
                className={classNames({
                  'row-action-btn': true,
                  'black-bg': !!prices.price,
                })}
                type="button"
                onClick={this.buyHandler}
              >
                Buy
              </button>

              {showError && hasError && isExpense && (
                <span className="row-action-error">
                  Select one of the options.
                </span>
              )}
            </div>
          )}
        </td>
      </tr>
    )
  }
}

export default OfferMeatRow
