import * as React from 'react'
import { Router, Redirect } from '@reach/router'
import { getToken } from '../utils/network'

import NotFoundPage from '../pages/404'
import Auth from './Auth/Auth'
import Login from './Auth/Login/Login'
import ForgotPassword from './Auth/ForgotPassword/ForgotPassword'
import Offers from './Offers/Offers'
import * as queryString from 'query-string'

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const queryParams = queryString.parse(rest.location.search)

  return getToken() || queryParams[rest.passThroughQueryParam]
    ? <Component {...rest} />
    : <Redirect from="" to="/app/auth/login" noThrow />
};

const Root: React.FunctionComponent<any> = () => {
  return (
    <div className="container-main">
      <Router>
        <Auth path="/app/auth">
          <Login path="/login" />
          <ForgotPassword path="/forgot-password" />
        </Auth>

        <ProtectedRoute path="/app/offers" component={Offers} passThroughQueryParam="secret" />
        <NotFoundPage default />
      </Router>
    </div>
  )
}

export default Root
