import * as React from 'react'
import Layout from '../../../components/Layout/Layout'
import { HeaderPosition, HeaderStyle } from '../../../components/Header'
import { FormFieldProps, FormFieldType } from '../../../components/FormField'
import FormField from '../../../components/FormField/FormField'
import { Link } from 'gatsby'

const field: FormFieldProps = {
  type: FormFieldType.email,
  placeholder: 'Email',
  name: 'email',
  required: true,
}

const ForgotPassword: React.FunctionComponent<any> = (): JSX.Element => {
  return (
    <Layout
      seo={{ title: 'Login' }}
      header={{
        links: [],
        position: HeaderPosition.fixed,
        style: HeaderStyle.light,
        border: false,
      }}
      footer={{ hide: true }}
    >
      <section className="form__container">
        <div className="form__wrapper">
          <form className="form">
            <h4 className="form__title">Forgot password?</h4>
            <p>
              Enter your email address and we will send you a password reset
              link
            </p>
            <FormField {...field} />
            <button type="submit" className="primary-button">
              Request Reset Link
            </button>
          </form>
          <div className="form__footer">
            <Link to="/app/auth/login" className="form__link">
              Back to Login?
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ForgotPassword
