import * as React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import './Auth.scss'

const Auth: React.FunctionComponent<any> = ({ children }) => {
  const data = useStaticQuery(query)
  return (
    <section className="auth__container">
      <Img fluid={data.bgImage.childImageSharp.fluid} />
      {children}
    </section>
  )
}

export default Auth

export const query = graphql`
  query {
    bgImage: file(relativePath: { eq: "bg-auth@3x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
