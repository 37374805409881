import * as React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { Alert } from 'react-bootstrap'

import Layout from '../../../components/Layout/Layout'
import FormField from '../../../components/FormField/FormField'

import { HeaderPosition, HeaderStyle } from '../../../components/Header'
import { FormFieldProps, FormFieldType } from '../../../components/FormField'
import { request, setToken, setUser } from '../../../utils/network'
import { ApiOperation } from '../../../utils/api'
import { ButtonWithLoader } from '../../../components/ButtonWithLoader/ButtonWithLoader'


import './Login.scss'

const fields: FormFieldProps[] = [
  {
    type: FormFieldType.text,
    placeholder: 'Username',
    name: 'username',
    required: true,
  },
  {
    type: FormFieldType.password,
    placeholder: 'Password',
    name: 'password',
    required: true,
  },
]

const Login: React.FunctionComponent<any> = () => {
  const [isChecked, toogleCheckbox] = React.useState<boolean>(false)
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [isError, setError] = React.useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setLoading(true)
    const formData = new FormData(e.target as HTMLFormElement)

    request({
      operation: ApiOperation.Login,
      data: {
        identifier: formData.get('username'),
        password: formData.get('password')
      }
    }).then((resp) => {
      setToken(resp.jwt)
      setUser(resp.user.username)
      setLoading(false)
      setError(false)
      navigate('/app/offers')
    }).catch(() => {
      setLoading(false)
      setError(true)
    })
  }

  return (
    <Layout
      seo={{ title: 'Login' }}
      header={{
        links: [],
        position: HeaderPosition.fixed,
        style: HeaderStyle.light,
        border: false,
      }}
      footer={{ hide: true }}
    >
      {isError && (
        <div className="toasts-container">
          <Alert variant="danger" onClose={() => { setError(false) }} dismissible>
            <p>There was a problem with your credentials</p>
          </Alert>
        </div>
      )}

      <section className="form__container">
        <div className="form__wrapper">
          <form className="form" onSubmit={handleSubmit}>
            <h4 className="form__title">Login</h4>
            {fields.map((field, i) => (
              <FormField key={i} {...field} />
            ))}
            <label className="check">
              <input
                className="check__input"
                type="checkbox"
                name="keepMeIn"
                checked={isChecked}
                onChange={() => toogleCheckbox(!isChecked)}
              />
              <span className="check__box" />
              Keep me signed in
            </label>
            <ButtonWithLoader text="Log in" isLoading={isLoading} type="submit" className="primary-button"/>
          </form>
          <div className="form__footer">
            So you can’t get in to your account? <br /> Did you{' '}
            <Link to="/app/auth/forgot-password" className="form__link">
              forget your password?
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Login
