import * as React from 'react'
import './Loader.scss'

export const Loader = ({ isLoading, children }: any) => {
  return (
    <React.Fragment>
      {
        isLoading
          ? (
            <div className="loader-container">
              <span className="spinner-border" role="status" style={{ width: '2em', height: '2em', borderWidth: '2px' }}>
                <span className="sr-only">Loading...</span>
              </span>
            </div>
          )
          : children
      }
    </React.Fragment>
  )
}