import * as React from 'react'
import * as classNames from 'classnames'
import OfferMeatRow from '../OffersMeat/OfferMeatRow'
import './OfferContainer.scss'
import { OfferContainerProps } from './index'

const OfferContainer: React.FunctionComponent<OfferContainerProps> = ({
  data,
  selectMeat,
  showError,
  weightUnits,
}): JSX.Element => {
  return (
    <React.Fragment>
      <tr className="label-row">
        <td />
        <td>{data.type}</td>
        <td />
        <td>{data.brand}</td>
        <td>{data.size}</td>
        <td>{data.shipment}</td>
        <td />
        <td className="text-right">
          {data.soldOut && (
            <button className="btn-soldout" disabled>
              Sold Out
            </button>
          )}
        </td>
      </tr>
      {data.meat.map((item, i: number) => (
        <OfferMeatRow
          className={classNames({ last: data.meat.length - 1 === i })}
          key={i}
          data={item}
          brand={data.brand}
          selectMeat={selectMeat}
          containerId={data.id}
          soldOut={data.soldOut}
          showError={showError}
          hasError={data.hasError}
          weightUnits={weightUnits}
        />
      ))}
      <tr className="other-row">
        <td colSpan={8} />
      </tr>
    </React.Fragment>
  )
}

export default OfferContainer
